.App {
  text-align: center;
  min-height: 100vh;
}

.App_lights_on {
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url("./images/day.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  color: black;
}

.App_lights_off {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("./images/night.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  color: white;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  color: rgb(15, 1, 1);
}

.App-link {
  color: #09d3ac;
}

.alert_text {
  padding-bottom: 50px;
  color: red;
  font-size: 16px;
  font-weight: bold;
  display: inline;
}
