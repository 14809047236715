.container_article_list_item {
  width: 100%;
  display: flex;
  padding-right: 100px;
  margin-top: 15px;
}

.container_article_preview {
  text-align: center;
  margin: 0 auto;
  border-radius: 3px;
  width: 95%;
  box-shadow: 6px 6px 6px 1px rgb(15, 15, 15);
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: auto;
  color: black;
}

.container_article_preview:hover {
  border: 1px solid gray;
}

.article_list_item_image {
  width: 100%;
}

.container_article_list_item_image {
  width: 100%;
  margin-left: 0;
}

.container_article_list_item_info {
  width: 95%;
  padding-bottom: 20px;
}

.article_list_item_title {
  margin-top: 10px;
  display: block;
  margin-bottom: 5px;
}

.preview_text {
  display: block;
}

.article_list_item_title_link {
  text-decoration: none;
  color: black;
  font-size: 24px;
}

.container_article_text_preview {
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.article_attributes {
  font-size: 16px;
  font-weight: bold;
}

.article_attributes > span {
  margin-left: 3px;
}

.article_attributes > NavLink {
  color: black;
}
.article_attributes_username,
.article_attributes_category {
  text-decoration: none;
  color: rgb(51, 160, 255);
}

.article_attributes_username:hover,
.article_attributes_category:hover {
  text-decoration: underline;
}

.article_list_item_username:hover {
  cursor: pointer;
}

.container_vote_arrows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 2px;
}

.lights_off_container_vote_arrows {
  border-right: 1px solid rgb(75, 74, 74);
}

.container_arrow_up {
  width: 50px;
  display: flex;
}

.arrow_up {
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-style: solid;
  border-color: black;
  border-width: 0px 3px 3px 0px;
  transform: rotate(225deg);
  transition: border-width 150ms ease-in-out;
  margin: 0 auto;
}

.voting_request_error {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 0px;
  padding-bottom: 20px;
  margin-top: 3px;
}

.container_vote_count {
  margin-bottom: 5px;
  justify-content: center;
  font-size: 16px;
}

.thumbs_up,
.thumbs_down {
  max-width: 16px;
}

.thumbs_up {
  margin-right: 8px;
}

.thumbs_down {
  margin-left: 8px;
}

.thumbs_up:hover,
.thumbs_down:hover {
  cursor: pointer;
}

.vote_count {
  margin: 0 auto;
  font-weight: bold;
}

.arrow_up:hover {
  border-bottom-width: 4px;
  border-right-width: 4px;
  cursor: pointer;
}

.container_arrow_down {
  display: flex;
  width: 50px;
}

.arrow_down {
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-style: solid;
  border-color: black;
  border-width: 0px 3px 3px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
  margin: 0 auto;
}

.arrow_down:hover {
  border-bottom-width: 4px;
  border-right-width: 4px;
  cursor: pointer;
}

.article_list_item_lightsOff {
  background-color: rgb(209, 209, 209);
  color: black;
  border-top: 1px solid rgb(83, 82, 82);
  border-bottom: 1px solid rgb(83, 82, 82);
  box-shadow: 6px 6px 4px 2px rgb(15, 15, 15);
}

.lightsOff_text {
  color: black;
}

.lightsOff_arrow {
  border-color: black;
}

@media only screen and (max-width: 768px) {
  .container_article_list_item {
    padding-right: 0px;
  }

  .hide_image {
    display: none;
  }
}
