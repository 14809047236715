.Textarea {
  resize: none;
  display: block;
  font-size: 16px;
  padding: 10px;
  margin: 10px auto;
  text-align: left;
  width: 85%;
  max-height: 400px;
  font-family: "Open Sans", sans-serif;
}

.Section {
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 100px;
}

.Form {
  margin: 0 auto;
  border-radius: 2px;
}

.Label {
  display: block;
  width: 100%;
  font-size: 16px;
}

.Select {
  width: 25%;
  height: 30px;
  margin-top: 0;
}

.Input {
  border: 1px solid black;
  display: block;
  border-radius: 10px;
}

.Button {
  border: 1px solid black;
  font-size: 16px;
  padding: 5px 10px;
}

.CreatePostButton {
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}

.CreatePostButton:hover {
  cursor: pointer;
}

.SearchBox {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  background-color: rgb(224, 223, 223);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
