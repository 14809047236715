.container_comments {
  margin: 0 auto;
  max-width: 800px;
}

.comment_username_link {
  text-decoration: none;
  color: rgb(51, 160, 255);
  font-weight: bold;
  margin-left: 5px;
}

.container_comment_content {
  border: 1px solid gray;
  margin-top: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.comment_content {
  font-size: 20px;
}

.comments_content_lights_off {
  background-color: rgba(0, 0, 0, 0.8);
}

.comment_author_info {
  width: 100%;
  margin-right: auto;
  text-align: left;
  padding-left: 5px;
  border-bottom: 1px solid gray;
}

.list_comment_item {
  list-style: none;
}

.hide {
  display: none;
}

.list_comment_item:hover .hide {
  display: block;
}

.edit_comment_btn,
.delete_comment_btn {
  margin: 5px;
  min-width: 150px;
  background-color: rgb(51, 160, 255);
  border-radius: 3px;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.edit_comment_btn:hover,
.delete_comment_btn:hover {
  cursor: pointer;
}
