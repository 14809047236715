.container_categories_list {
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
}
.categories_list {
  list-style: none;
  margin: auto;
  padding-left: 0;
}

.container_categories_list_header {
  padding-left: 2px;
  padding-right: 2px;
}

.categories_all_link {
  display: block;
  text-decoration: none;
  color: black;
  background-color: rgba(255, 255, 255, 0);
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.categories_popularity_link {
  display: block;
  text-decoration: none;
  color: black;
  background-color: rgba(255, 255, 255, 0);
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.categories_all_link:focus {
  border-left: 3px solid rgb(51, 160, 255);
  color: rgb(51, 160, 255);
  background-color: rgba(109, 108, 108, 0.2);
}

.categories_popularity_link:focus {
  border-left: 3px solid rgb(51, 160, 255);
  color: rgb(51, 160, 255);
  background-color: rgba(109, 108, 108, 0.2);
}

.categories_all_link:hover,
.categories_popularity_link:hover {
  background-color: rgba(109, 108, 108, 0.2);
}

.categories_list li {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.lights_off_bg {
  color: white;
}

.lights_off_link_bg {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.lights_off_link_bg:focus {
  background-color: rgba(214, 214, 214, 0.2);
}
