.header {
  width: 100%;
  background-color: rgba(126, 125, 125, 0.3);
  margin-bottom: 70px;
}

.header_lights_off {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 70px;
  margin-right: 5px;
}

#lights_off_text {
  color: white;
}

.container_header_content {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
}

.container_moon {
  width: 25px;
  margin-right: 10px;
}
.header_moon {
  width: 100%;
  vertical-align: middle;
}

.header_moon:hover {
  cursor: pointer;
}

.navbar_links {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 16px;
  align-items: center;
  font-size: 20px;
}

.container_logo {
  width: 50%;
  text-align: left;
}

.top_header_logo {
  width: 150px;
}

.navbar_link_signup,
.navbar_link_login {
  text-decoration: none;
  margin-left: 10px;
  color: black;
  font-weight: bold;
}

.navbar_link_signup:hover,
.navbar_link_login:hover,
.navbar_link_account:hover {
  cursor: pointer;
  text-decoration: underline;
}

.navbar_link_account {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.light_switch {
  margin-top: 20px;
}

.light_switch_on {
  color: rgb(56, 59, 11);
  font-weight: bold;
  font-size: 16px;
}

.light_switch_off {
  color: white;
  font-weight: bold;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .header {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 400px) {
  .container_header_logged_in_links,
  .container_header_logged_out_links {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 415px) {
  .container_moon {
    display: none;
  }

  .navbar_links {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    padding-right: 10px;
    font-size: 16px;
    align-items: center;
    font-size: 20px;
  }
}

@media only screen and (min-width: 416px) {
  .light_switch {
    display: none;
  }
}
