@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,700&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  padding: env(safe-area-inset);
  min-height: 100vh;
  overflow: auto;
  font-family: IBM Plex Sans, Roboto, sans-serif;
  background-color: rgb(229, 226, 226);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
