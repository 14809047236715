@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,700&display=swap);
.Textarea {
  resize: none;
  display: block;
  font-size: 16px;
  padding: 10px;
  margin: 10px auto;
  text-align: left;
  width: 85%;
  max-height: 400px;
  font-family: "Open Sans", sans-serif;
}

.Section {
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 100px;
}

.Form {
  margin: 0 auto;
  border-radius: 2px;
}

.Label {
  display: block;
  width: 100%;
  font-size: 16px;
}

.Select {
  width: 25%;
  height: 30px;
  margin-top: 0;
}

.Input {
  border: 1px solid black;
  display: block;
  border-radius: 10px;
}

.Button {
  border: 1px solid black;
  font-size: 16px;
  padding: 5px 10px;
}

.CreatePostButton {
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}

.CreatePostButton:hover {
  cursor: pointer;
}

.SearchBox {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  background-color: rgb(224, 223, 223);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.header {
  width: 100%;
  background-color: rgba(126, 125, 125, 0.3);
  margin-bottom: 70px;
}

.header_lights_off {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 70px;
  margin-right: 5px;
}

#lights_off_text {
  color: white;
}

.container_header_content {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
}

.container_moon {
  width: 25px;
  margin-right: 10px;
}
.header_moon {
  width: 100%;
  vertical-align: middle;
}

.header_moon:hover {
  cursor: pointer;
}

.navbar_links {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 16px;
  align-items: center;
  font-size: 20px;
}

.container_logo {
  width: 50%;
  text-align: left;
}

.top_header_logo {
  width: 150px;
}

.navbar_link_signup,
.navbar_link_login {
  text-decoration: none;
  margin-left: 10px;
  color: black;
  font-weight: bold;
}

.navbar_link_signup:hover,
.navbar_link_login:hover,
.navbar_link_account:hover {
  cursor: pointer;
  text-decoration: underline;
}

.navbar_link_account {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.light_switch {
  margin-top: 20px;
}

.light_switch_on {
  color: rgb(56, 59, 11);
  font-weight: bold;
  font-size: 16px;
}

.light_switch_off {
  color: white;
  font-weight: bold;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .header {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 400px) {
  .container_header_logged_in_links,
  .container_header_logged_out_links {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 415px) {
  .container_moon {
    display: none;
  }

  .navbar_links {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    padding-right: 10px;
    font-size: 16px;
    align-items: center;
    font-size: 20px;
  }
}

@media only screen and (min-width: 416px) {
  .light_switch {
    display: none;
  }
}

.bg_mobile_view_registration_page {
  display: none;
}

.container_desktop_view_registration_hero {
  display: none;
}

.logo_registration {
  width: 150px;
  margin: 0 auto;
}

#header_registration_form {
  margin-top: 0;
  color: black;
}

#no_radius {
  border-radius: 0px;
}

.RegistrationForm {
  padding-bottom: 30px;
  width: 100%;
  min-height: 100vh;
  background-image: url(/static/media/main_bg.90ae1727.jpeg);
}

.container_RegistrationForm_username,
.container_RegistrationForm_password,
.container_RegistrationForm_profile,
.container_RegistrationForm_email,
.container_RegistrationForm_image_url,
.container_RegistrationForm_fullname {
  width: 80%;
  margin: 10px auto;
}

#label_registration_fullname,
#label_registration_username,
#label_registration_password,
#label_registration_email,
#label_registration_profile,
#label_registration_image_url {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.RegistrationForm_email,
.RegistrationForm_username,
.RegistrationForm_image_url,
.RegistrationForm_password,
.RegistrationForm_fullname {
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
  font-size: 16px;
}

.RegistrationForm_email:focus,
.RegistrationForm_username:focus,
.RegistrationForm_image_url:focus,
.RegistrationForm_password:focus,
.RegistrationForm_fullname:focus,
.RegistrationForm_profile:focus {
  outline-width: 0;
}

.RegistrationForm_email:hover,
.RegistrationForm_username:hover,
.RegistrationForm_image_url:hover,
.RegistrationForm_password:hover,
.RegistrationForm_fullname:hover,
.RegistrationForm_profile:hover {
  border: 1px solid rgb(110, 110, 110);
}

.RegistrationForm_profile {
  display: block;
  font-size: 16px;
  padding: 10px;
  margin: 10px auto;
  text-align: left;
  width: 100%;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  resize: none;
  min-height: 150px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
}

.RegistrationForm_submit_btn {
  min-width: 200px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  background-color: white;
  color: black;
  font-weight: bold;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: 2px 2px 5px;
}

.RegistrationForm_submit_btn:hover {
  cursor: pointer;
  border: 1px solid rgb(110, 110, 110);
}

.RegistrationForm_login_link {
  text-decoration: none;
  color: teal;
}

.red {
  margin-top: 2px;
  color: rgb(255, 0, 0);
}

#message_redirect {
  color: black;
}
@media only screen and (min-width: 1024px) {
  .container_desktop_view_registration_hero {
    display: block;
    font-size: 30px;
    padding-top: 250px;
    width: 75%;
    margin: 0 auto;
  }

  .container_registration_page {
    display: flex;
    min-height: 100vh;
  }

  .container_registration_form {
    width: 30%;
    min-height: 100vh;
  }

  .RegistrationForm {
    background-image: none;
    background-color: rgb(211, 211, 211);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .bg_desktop_view_registration_page {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))),
      url(/static/media/celebration.e2fa1dca.jpeg) no-repeat center center fixed;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(/static/media/celebration.e2fa1dca.jpeg) no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    overflow: auto;
    width: 70%;
  }
}

.mobile_view_bg_login_page {
  min-height: 100vh;
  background-image: url(/static/media/main_bg.90ae1727.jpeg);
}

.container_login_form {
  margin: 0 auto;
  min-height: 100vh;
}

.LoginForm {
  padding-top: 60px;
  padding-bottom: 60px;
}

.loading_gif {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 10%;
}

.container_LoginForm_username,
.container_LoginForm_password {
  width: 80%;
  margin: 10px auto;
}

#label_login_username,
#label_login_password {
  display: block;
  width: 100%;
  text-align: left;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.LoginForm_password,
.LoginForm_username {
  width: 100%;
  padding: 10px;
  font-size: 21px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
  font-size: 16px;
}

.LoginForm_password:hover,
.LoginForm_username:hover {
  border: 1px solid rgb(110, 110, 110);
}

.LoginForm_password:focus,
.LoginForm_username:focus {
  outline-width: 0;
}

.LoginForm_submit_btn {
  padding: 10px;
  min-width: 200px;
  border-radius: 10px;
  background-color: white;
  font-weight: bold;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: 2px 2px 5px;
}

.LoginForm_submit_btn:hover {
  cursor: pointer;
  border: 1px solid rgb(110, 110, 110);
}

.btn_register_link {
  color: teal;
  text-decoration: none;
}

#message_redirect {
  font-weight: bold;
  color: black;
}

.container_desktop_view_login_hero {
  padding-top: 300px;
  font-size: 40px;
  margin: 0 auto;
  width: 85%;
}

.error {
  margin-top: 2px;
  color: rgb(255, 0, 0);
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
  .LoginForm {
    margin-left: 0;
    max-width: 600px;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile_view_bg_login_page {
    background-image: none;
  }
  .container_login_page {
    display: flex;
    min-height: 100vh;
  }

  .container_login_form {
    min-height: 100vh;
    background-color: rgb(211, 211, 211);
    width: 30%;
    padding-top: 40px;
  }

  .container_desktop_view_login_hero {
    color: white;
    width: 80%;
    padding-top: 250px;
  }

  .bg_desktop_view_login_image {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.7))),
      url(/static/media/japan_night.9651d4fb.jpeg) no-repeat center center fixed;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(/static/media/japan_night.9651d4fb.jpeg) no-repeat center center fixed;
    background-size: cover;
    padding-bottom: 50px;
    min-height: 100vh;
    overflow: hidden;
    width: 70%;
  }

  .LoginForm {
    padding-top: 60px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1023px) {
  .bg_desktop_view_login_image {
    display: none;
  }
}

.category_link {
  text-decoration: none;
  color: black;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(255, 255, 255, 0);
}

.category_link:focus {
  border-left: 3px solid rgb(51, 160, 255);
  background-color: rgba(109, 108, 108, 0.2);
  color: rgb(51, 160, 255);
}

.category_link:hover {
  background-color: rgba(109, 108, 108, 0.2);
}

.lights_off_category_bg {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.lights_off_category_bg:focus {
  background-color: rgba(214, 214, 214, 0.2);
}

.lights_off_category_bg:hover {
  background-color: rgba(214, 214, 214, 0.2);
}

.container_categories_list {
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
}
.categories_list {
  list-style: none;
  margin: auto;
  padding-left: 0;
}

.container_categories_list_header {
  padding-left: 2px;
  padding-right: 2px;
}

.categories_all_link {
  display: block;
  text-decoration: none;
  color: black;
  background-color: rgba(255, 255, 255, 0);
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.categories_popularity_link {
  display: block;
  text-decoration: none;
  color: black;
  background-color: rgba(255, 255, 255, 0);
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.categories_all_link:focus {
  border-left: 3px solid rgb(51, 160, 255);
  color: rgb(51, 160, 255);
  background-color: rgba(109, 108, 108, 0.2);
}

.categories_popularity_link:focus {
  border-left: 3px solid rgb(51, 160, 255);
  color: rgb(51, 160, 255);
  background-color: rgba(109, 108, 108, 0.2);
}

.categories_all_link:hover,
.categories_popularity_link:hover {
  background-color: rgba(109, 108, 108, 0.2);
}

.categories_list li {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.lights_off_bg {
  color: white;
}

.lights_off_link_bg {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.lights_off_link_bg:focus {
  background-color: rgba(214, 214, 214, 0.2);
}

.container_article_list_item {
  width: 100%;
  display: flex;
  padding-right: 100px;
  margin-top: 15px;
}

.container_article_preview {
  text-align: center;
  margin: 0 auto;
  border-radius: 3px;
  width: 95%;
  box-shadow: 6px 6px 6px 1px rgb(15, 15, 15);
  background-color: rgba(255, 255, 255, 0.9);
  background-size: cover;
  overflow: auto;
  color: black;
}

.container_article_preview:hover {
  border: 1px solid gray;
}

.article_list_item_image {
  width: 100%;
}

.container_article_list_item_image {
  width: 100%;
  margin-left: 0;
}

.container_article_list_item_info {
  width: 95%;
  padding-bottom: 20px;
}

.article_list_item_title {
  margin-top: 10px;
  display: block;
  margin-bottom: 5px;
}

.preview_text {
  display: block;
}

.article_list_item_title_link {
  text-decoration: none;
  color: black;
  font-size: 24px;
}

.container_article_text_preview {
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.article_attributes {
  font-size: 16px;
  font-weight: bold;
}

.article_attributes > span {
  margin-left: 3px;
}

.article_attributes > NavLink {
  color: black;
}
.article_attributes_username,
.article_attributes_category {
  text-decoration: none;
  color: rgb(51, 160, 255);
}

.article_attributes_username:hover,
.article_attributes_category:hover {
  text-decoration: underline;
}

.article_list_item_username:hover {
  cursor: pointer;
}

.container_vote_arrows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 2px;
}

.lights_off_container_vote_arrows {
  border-right: 1px solid rgb(75, 74, 74);
}

.container_arrow_up {
  width: 50px;
  display: flex;
}

.arrow_up {
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-style: solid;
  border-color: black;
  border-width: 0px 3px 3px 0px;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  -webkit-transition: border-width 150ms ease-in-out;
  transition: border-width 150ms ease-in-out;
  margin: 0 auto;
}

.voting_request_error {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 0px;
  padding-bottom: 20px;
  margin-top: 3px;
}

.container_vote_count {
  margin-bottom: 5px;
  justify-content: center;
  font-size: 16px;
}

.thumbs_up,
.thumbs_down {
  max-width: 16px;
}

.thumbs_up {
  margin-right: 8px;
}

.thumbs_down {
  margin-left: 8px;
}

.thumbs_up:hover,
.thumbs_down:hover {
  cursor: pointer;
}

.vote_count {
  margin: 0 auto;
  font-weight: bold;
}

.arrow_up:hover {
  border-bottom-width: 4px;
  border-right-width: 4px;
  cursor: pointer;
}

.container_arrow_down {
  display: flex;
  width: 50px;
}

.arrow_down {
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-style: solid;
  border-color: black;
  border-width: 0px 3px 3px 0px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: border-width 150ms ease-in-out;
  transition: border-width 150ms ease-in-out;
  margin: 0 auto;
}

.arrow_down:hover {
  border-bottom-width: 4px;
  border-right-width: 4px;
  cursor: pointer;
}

.article_list_item_lightsOff {
  background-color: rgb(209, 209, 209);
  color: black;
  border-top: 1px solid rgb(83, 82, 82);
  border-bottom: 1px solid rgb(83, 82, 82);
  box-shadow: 6px 6px 4px 2px rgb(15, 15, 15);
}

.lightsOff_text {
  color: black;
}

.lightsOff_arrow {
  border-color: black;
}

@media only screen and (max-width: 768px) {
  .container_article_list_item {
    padding-right: 0px;
  }

  .hide_image {
    display: none;
  }
}

.select_menu {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 20px;
  padding-left: 5px;
}

.select_menu:focus {
  outline: none;
}

.ticker_title {
  font-weight: bold;
  font-size: 20px;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

.tcontainer {
  width: 100%;
  overflow: hidden;
}

.ticker-wrap {
  width: 100%;
  padding-left: 100%;
  background-color: black;
  margin-bottom: 30px;
  margin-top: 20px;
}

.ticker-wrap-lights-off {
  width: 100%;
  padding-left: 100%;
  background-color: white;
  margin-bottom: 30px;
  margin-top: 20px;
}

.ticker-move {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-name: ticker;
          animation-name: ticker;
  -webkit-animation-duration: 90s;
          animation-duration: 90s;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ticker-move:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.ticker-item {
  display: inline-block;
  padding: 0 2rem;
  -webkit-font-smoothing: antialiased;
}

.ticker_links {
  text-decoration: none;
  font-weight: bold;
  color: white;
  font-size: 24px;
}

.ticker_links_lights_off {
  text-decoration: none;
  font-weight: bold;
  color: black;
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .ticker-move {
    -webkit-animation-duration: 120s;
            animation-duration: 120s;
  }
}

.top_users_list {
  padding: 0;
  width: 100%;
  margin: 0 auto;
}

.top_users_list_item {
  list-style: none;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
}

.top_users_list_item:hover {
  background-color: rgba(109, 108, 108, 0.2);
}

.top_users_list_item_lights_off:hover {
  background-color: rgba(109, 108, 108, 0.2);
}

.container_top_user_username {
  width: 40%;
  text-align: left;
}

.top_users_link {
  text-decoration: none;
  color: black;
  word-wrap: break-word;
}

.top_users_link_lights_off {
  text-decoration: none;
  color: white;
  word-wrap: break-word;
}

.container_top_user_image {
  width: 20%;
  margin-left: 25px;
}

.top_user_image {
  width: 20px;
  height: 20px;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: 10px;
}

.container_top_user_chatbox {
  width: 15%;
}

.top_user_chatbox {
  width: 20px;
}

@media only screen and (max-width: 768px) {
  .hide_icons {
    display: none;
  }

  .top_users_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .top_users_list_item {
    margin: 0;
    padding: 0;
    display: block;
  }

  .container_top_user_username {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
}

.container_top_users_list {
  margin-top: 14px;
  margin-right: 50px;
  width: 30%;
  border: 1px solid rgb(83, 82, 82);
  border-radius: 5px;
  height: 100%;
  padding-bottom: 20px;
  color: black;
  box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
}

.top_users_title {
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: rgba(109, 108, 108, 0.2);
  margin-top: 0;
}

.flash_icon {
  max-width: 20px;
  vertical-align: middle;
}

.container_top_users_list_lights_off {
  margin-top: 14px;
  margin-right: 50px;
  width: 30%;
  border: 1px solid rgb(83, 82, 82);
  border-radius: 5px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
}

.flex_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container_topbar_menu {
  display: flex;
  width: 100%;
  height: 60px;
}

.container_dropdown_menu {
  width: 75%;
  height: 100%;
}

.container_topbar_create_btn {
  width: 25%;
  height: 100%;
}

.container_articles_section {
  width: 80%;
}

.container_sidebar_create_post_btn {
  width: 100%;
}

.right_sidebar_menu {
  width: 20%;
  border: 1px solid rgb(83, 82, 82);
  border-radius: 5px;
  padding-bottom: 20px;
  height: 100%;
  font-weight: bold;
  box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
}

.lights_on_bg {
  background-color: rgba(255, 255, 255, 0.8);
}

.lights_off_bg {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.lights_off_bg_shadow {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
}

.create_post_btn_bg {
  color: white;
  background-color: rgb(51, 160, 255);
  border: none;
}

@media only screen and (max-width: 768px) {
  .hide_sidebar_menu,
  .hide_top_users_list {
    display: none;
  }

  .container_articles_section {
    width: 100%;
  }

  .lights_on_bg {
    padding-bottom: 25px;
    margin-right: 10px;
    margin-left: 10px;
    box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
    border-radius: 2px;
  }

  .lights_off_bg {
    background-color: rgba(0, 0, 0, 0.7);
    padding-bottom: 25px;
    margin-right: 10px;
    margin-left: 10px;
    box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
    border-radius: 2px;
  }

  .mobile_top_users_title {
    padding-top: 10px;
    text-decoration: underline;
  }
}

@media only screen and (min-width: 769px) {
  .hide_topbar_menu {
    display: none;
  }

  .hide_top_users {
    display: none;
  }

  .right_sidebar_menu {
    margin-top: 14px;
  }
}

.container_article_image {
  width: 100%;
  margin: 30px auto;
}

.article_image {
  width: 100%;
  object-fit: cover;
  height: 300px;
}

.container_article {
  max-width: 800px;
  margin: 100px auto;
  padding-bottom: 50px;
  padding-right: 0;
}

.link_user_profile {
  text-decoration: none;
  color: rgb(51, 160, 255);
  font-weight: bold;
}

.link_user_profile:hover {
  text-decoration: underline;
}

.article_date,
.article_author {
  font-size: 16px;
}

.article_content {
  white-space: pre-line;
  text-align: justify;
  text-indent: 5%;
}

.Article_edit_post_btn {
  min-width: 200px;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  background-color: rgb(90, 90, 90);
  padding: 10px;
  color: white;
  font-weight: bold;
  background-color: rgb(51, 160, 255);
}

.Article_edit_post_btn:hover {
  cursor: pointer;
}

.Article_edit_post_btn:focus {
  outline-width: 0;
}

@media only screen and (min-width: 568px) {
  .container_article_image {
    max-width: 800px;
  }
  .article_image {
    height: 400px;
    object-fit: cover;
  }
}

.AddCommentForm {
  max-width: 800px;
  margin-bottom: 100px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.label_add_comment_form {
  display: block;
  width: 90%;
  margin: 5px auto;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.AddCommentForm_comment_box {
  width: 90%;
  padding-left: 10px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 10px;
  resize: none;
  border-radius: 10px;
  text-align: left;
  font-size: 16px;
}

.AddCommentForm_comment_box:focus {
  outline-width: 0;
}

.AddCommentForm_submit_btn {
  display: block;
  margin: 10px auto;
  border-radius: 10px;
  min-width: 200px;
  padding: 6px;
  font-size: 16px;
  background-color: rgb(51, 160, 255);
  border: none;
  color: white;
  font-weight: bold;
}

.AddCommentForm_submit_btn:hover {
  cursor: pointer;
}

.AddCommentForm_submit_btn:focus {
  outline-width: 0;
}

.EditCommentForm {
  margin-bottom: 100px;
  margin-top: 20px;
}

.label_edit_comment_form {
  display: block;
  width: 100%;
  margin: 5px auto;
  text-align: left;
  font-size: 16px;
}

.EditCommentForm_comment_box {
  width: 100%;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 5px;
  padding-left: 10px;
  resize: none;
  border-radius: 10px;
  text-align: left;
  font-size: 16px;
}

.EditCommentForm_comment_box:focus {
  outline-width: 0;
}

.container_EditCommentForm_btns {
  display: flex;
}

.EditCommentForm_submit_btn,
.EditCommentForm_cancel_btn {
  display: block;
  margin: 10px auto;
  border-radius: 10px;
  min-width: 200px;
  padding: 6px;
  font-size: 16px;
  background-color: rgb(51, 160, 255);
  color: white;
  font-weight: bold;
  border: none;
}

.EditCommentForm_submit_btn:hover,
.EditCommentForm_cancel_btn:hover {
  cursor: pointer;
}

.EditCommentForm_submit_btn:focus,
.EditCommentForm_cancel_btn:focus {
  outline: none;
}

.container_comments {
  margin: 0 auto;
  max-width: 800px;
}

.comment_username_link {
  text-decoration: none;
  color: rgb(51, 160, 255);
  font-weight: bold;
  margin-left: 5px;
}

.container_comment_content {
  border: 1px solid gray;
  margin-top: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.comment_content {
  font-size: 20px;
}

.comments_content_lights_off {
  background-color: rgba(0, 0, 0, 0.8);
}

.comment_author_info {
  width: 100%;
  margin-right: auto;
  text-align: left;
  padding-left: 5px;
  border-bottom: 1px solid gray;
}

.list_comment_item {
  list-style: none;
}

.hide {
  display: none;
}

.list_comment_item:hover .hide {
  display: block;
}

.edit_comment_btn,
.delete_comment_btn {
  margin: 5px;
  min-width: 150px;
  background-color: rgb(51, 160, 255);
  border-radius: 3px;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.edit_comment_btn:hover,
.delete_comment_btn:hover {
  cursor: pointer;
}

.comments_list {
  margin: 0 auto;
  padding-left: 0;
}

.label_add_comment {
  font-weight: bold;
}

.container_article_page_return_link {
  max-width: 1400px;
  margin: 0 auto;
  text-align: left;
  padding-left: 0;
}

.article_page_return_link {
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
}

.article_page_return_link:hover {
  text-decoration: underline;
}

.lights_off_return_btn {
  color: white;
}

.container_ArticlePage {
  padding-top: 20px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.ArticlePage_lights_off {
  background-color: rgba(0, 0, 0, 0.85);
}

.ArticlePage_lights_on {
  background-color: rgba(215, 215, 215, 0.9);
}

.error_message_no_article {
  color: red;
}

.AddArticleForm {
  padding-bottom: 40px;
  padding-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.AddArticleForm_lights_off {
  background-color: rgba(0, 0, 0, 0.7);
}

.AddArticleForm_lights_on {
  background-image: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(220, 220, 220, 0.2)),
      to(rgba(220, 220, 220, 0.2))
    ),
    url(/static/media/main_bg.90ae1727.jpeg);
  background-image: linear-gradient(
      rgba(220, 220, 220, 0.2),
      rgba(220, 220, 220, 0.2)
    ),
    url(/static/media/main_bg.90ae1727.jpeg);
}

.container_AddArticleForm_header {
  text-align: center;
}

.container_AddArticleForm_title,
.container_AddArticleForm_image_url {
  width: 80%;
  margin: 20px auto;
}

.label_add_article_form {
  text-align: center;
  font-weight: bold;
}

.AddArticleForm_article_image_url,
.AddArticleForm_article_title {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
}

.AddArticleForm_article_content {
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
  font-size: 16px;
  height: 350px;
}

.AddArticleForm_select {
  margin: 10px auto;
  font-size: 16px;
  min-width: 200px;
  padding-left: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
  font-size: 16px;
}

.AddArticleForm_article_image_url:focus,
.AddArticleForm_article_title:focus,
.AddArticleForm_select:focus,
.AddArticleForm_article_content:focus {
  outline-width: 0;
}

.container_AddArticleForm_btn {
  margin-top: 10px;
  display: flex;
}

.AddArticleForm_post_btn,
.AddArticleForm_cancel_btn {
  display: block;
  width: 25%;
  margin: 10px auto;
  border-radius: 10px;
  border: none;
  background-color: rgb(51, 160, 255);
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}

.AddArticleForm_post_btn:hover,
.AddArticleForm_cancel_btn:hover {
  cursor: pointer;
}

.AddArticleForm_post_btn:focus,
.AddArticleForm_cancel_btn:focus {
  outline-width: 0;
}

.EditArticleForm {
  padding-bottom: 40px;
  padding-top: 20px;
  padding-top: 20px;
  margin-bottom: 30px;
}

.EditArticleForm_lights_on {
  background-image: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(220, 220, 220, 0.2)),
      to(rgba(220, 220, 220, 0.2))
    ),
    url(/static/media/main_bg.90ae1727.jpeg);
  background-image: linear-gradient(
      rgba(220, 220, 220, 0.2),
      rgba(220, 220, 220, 0.2)
    ),
    url(/static/media/main_bg.90ae1727.jpeg);
}

.EditArticleForm_lights_off {
  background-color: rgb(0, 0, 0, 0.7);
}

.container_EditArticleForm_header {
  text-align: center;
}

.container_EditArticleForm_title,
.container_EditArticleForm_image_url {
  width: 80%;
  margin: 20px auto;
}

.label_edit_article_form {
  text-align: center;
  font-weight: bold;
}

.EditArticleForm_article_image_url,
.EditArticleForm_article_title {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
}

.EditArticleForm_article_content {
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
  font-size: 16px;
  height: 350px;
}

.EditArticleForm_select {
  margin: 10px auto;
  font-size: 16px;
  min-width: 200px;
  padding-left: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
  font-size: 16px;
}
.EditArticleForm_article_image_url,
.EditArticleForm_article_title,
.EditArticleForm_select,
.EditArticleForm_article_content {
  border: none;
}

.EditArticleForm_article_image_url:focus,
.EditArticleForm_article_title:focus,
.EditArticleForm_select:focus,
.EditArticleForm_article_content:focus {
  outline: none;
}

.container_EditArticleForm_btn {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.EditArticleForm_delete_btn,
.EditArticleForm_cancel_btn,
.EditArticleForm_edit_btn {
  display: block;
  width: 25%;
  margin: 10px auto;
  border-radius: 10px;
  border: none;
  background-color: rgb(51, 160, 255);
  color: white;
}

.EditArticleForm_delete_btn:hover,
.EditArticleForm_cancel_btn:hover,
.EditArticleForm_edit_btn:hover {
  cursor: pointer;
}

.container_user_account_info {
  display: flex;
  flex-direction: column;
  height: 220px;
  padding-right: 3px;
  padding-left: 3px;
}

.container_user_account_image {
  width: 100%;
  margin: 0 auto;
}

.account_image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border: 1px solid rgb(185, 185, 185);
}

.UserAccount_edit_btn {
  width: 98%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  margin-bottom: 50px;
  background-color: rgb(51, 160, 255);
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.UserAccount_edit_btn:hover {
  cursor: pointer;
}

.UserAccount_edit_btn:focus {
  outline-width: 0;
}

.user_account_header {
  margin-top: 0;
}

.user_profile_text {
  margin-top: 0;
}

@media only screen and (min-width: 568px) {
  .container_user_account {
    max-width: 800px;
    margin: 0 auto;
  }

  .container_user_account_image {
    margin-left: 0;
    width: 40%;
  }

  .container_user_account_info {
    width: 50%;
  }

  .container_UserAccount_profile {
    display: flex;
    flex-wrap: wrap;
  }

  .container_UserAccount_edit_btn {
    text-align: left;
  }

  .UserAccount_edit_btn {
    width: 40%;
  }
}

@media only screen and (max-width: 500px) {
  .container_user_account_image {
    width: 100%;
    margin: 0 auto;
  }
}

.container_UserAccountPage_lights_off {
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgb(37, 37, 37);
  border-radius: 5px;
  font-size: 16px;
  padding-top: 15px;
}

.container_UserAccountPage_lights_on {
  background-color: rgba(255, 255, 255, 0.75);
  border: 1px solid rgb(121, 121, 121);
  border-radius: 5px;
  font-size: 16px;
  padding-top: 15px;
}

.user_account_return_link_lights_off {
  display: block;
  border: none;
  color: white;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-left: 20px;
}

.user_account_return_link_lights_off:active {
  color: white;
}

.user_account_return_link_lights_on {
  display: block;
  border: none;
  color: black;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-left: 20px;
}

.user_account_return_link_lights_on:active {
  color: black;
}

.user_account_return_link:hover {
  text-decoration: underline;
}

.container_return_link {
  max-width: 1400px;
  margin-bottom: 50px;
}

.user_account_return_link:focus {
  border: none;
}

.user_article_list {
  padding-left: 0;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid rgb(149, 149, 149);
  border-radius: 2px;
}

.user_article_list_item {
  list-style: none;
  padding: 10px;
}

.user_article_link_lights_on {
  color: black;
  text-decoration: none;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.user_article_link_lights_off {
  color: white;
  text-decoration: none;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

#user_article_title {
  color: rgb(51, 160, 255);
}

#user_article_title:hover {
  text-decoration: underline;
}

.EditUserForm {
  padding-top: 50px;
  border-radius: 10px;
  padding-bottom: 20px;
}

.container_EditUserForm_password,
.container_EditUserForm_profile,
.container_EditUserForm_image_url {
  width: 80%;
  margin: 0 auto;
}

.label_edit_user {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
}

.EditUserForm_profile {
  display: block;
  font-size: 16px;
  padding: 10px;
  margin: 10px auto;
  text-align: left;
  width: 100%;
  border-radius: 10px;
  resize: none;
  height: 150px;
}

.EditUserForm_password,
.EditUserForm_image_url {
  display: block;
  font-size: 16px;
  padding: 10px;
  margin: 10px auto;
  width: 100%;
  border-radius: 10px;
}

.EditUserForm_password:focus,
.EditUserForm_image_url:focus,
.EditUserForm_profile:focus {
  outline-width: 0;
}

.EditUserForm_submit_btn,
.EditUserForm_cancel_btn {
  border-radius: 10px;
  min-width: 200px;
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  background-color: rgb(51, 160, 255);
  color: white;
  font-weight: bold;
  border: none;
}

.EditUserForm_submit_btn:hover,
.EditUserForm_cancel_btn:hover {
  cursor: pointer;
}

.EditUserForm_submit_btn:focus,
.EditUserForm_cancel_btn:focus {
  outline-width: 0;
}

.container_EditUserPage {
  margin-bottom: 50px;
}

.EditUserPage_lights_on {
  background-image: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(220, 220, 220, 0.2)),
      to(rgba(220, 220, 220, 0.2))
    ),
    url(/static/media/main_bg.90ae1727.jpeg);
  background-image: linear-gradient(
      rgba(220, 220, 220, 0.2),
      rgba(220, 220, 220, 0.2)
    ),
    url(/static/media/main_bg.90ae1727.jpeg);
  border-radius: 5px;
}

.EditUserPage_lights_off {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}

.container_home_btn {
  width: 100%;
  margin: 0 auto;
}

.container_user_profile_info {
  display: flex;
  flex-direction: column;
}

.container_user_profile_image {
  width: 100%;
  margin: 0 auto;
}

.profile_image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
  object-position: 50% 50%;
  padding-right: 2px;
  padding-left: 2px;
}

.user_profile_header {
  margin-top: 0;
}

.error_message_no_user {
  color: red;
}

@media only screen and (min-width: 568px) {
  .container_user_profile {
    max-width: 800px;
    margin: 0 auto;
  }

  .container_user_profile_section {
    display: flex;
    flex-wrap: wrap;
  }

  .container_home_btn {
    max-width: 1400px;
    margin-bottom: 50px;
  }

  .container_user_profile_image {
    margin-left: 0;
    width: 40%;
  }

  .container_user_profile_info {
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .container_user_profile_image {
    width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 736px) {
  .profile_image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 10px;
    object-position: 50% 50%;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.container_UserProfilePage_lights_on {
  background-color: rgba(255, 255, 255, 0.75);
  border: 1px solid rgb(121, 121, 121);
  border-radius: 5px;
  font-size: 16px;
  padding-top: 15px;
  margin-bottom: 20px;
}

.container_UserProfilePage_lights_off {
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgb(37, 37, 37);
  border-radius: 5px;
  font-size: 16px;
  padding-top: 15px;
  margin-bottom: 20px;
}

.container_user_profile_page_return_btn {
  max-width: 1400px;
  margin-bottom: 50px;
}

.user_profile_page_return_btn {
  margin-bottom: 20px;
}

.user_profile_page_return_btn_lights_off {
  display: block;
  border: none;
  color: white;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-left: 2px;
  background-color: inherit;
  border: none;
}

.user_profile_page_return_btn_lights_on {
  display: block;
  border: none;
  color: black;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-left: 2px;
  background-color: inherit;
  border: none;
}

.user_profile_page_return_btn:focus {
  outline: none;
}

.user_profile_page_return_btn_lights_off:active {
  color: white;
}

.user_profile_page_return_btn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.user_article_title {
  color: rgb(51, 160, 255);
}

@media only screen and (max-width: 450px) {
  .user_article_link {
    font-size: 16px;
  }
}

.App {
  text-align: center;
  min-height: 100vh;
}

.App_lights_on {
  background: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(255, 255, 255, 0.3)),
      to(rgba(255, 255, 255, 0.3))
    ),
    url(/static/media/day.56241210.jpeg) no-repeat center center fixed;
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url(/static/media/day.56241210.jpeg) no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  color: black;
}

.App_lights_off {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8))),
    url(/static/media/night.aba02469.jpeg) no-repeat center center fixed;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(/static/media/night.aba02469.jpeg) no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  color: white;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  color: rgb(15, 1, 1);
}

.App-link {
  color: #09d3ac;
}

.alert_text {
  padding-bottom: 50px;
  color: red;
  font-size: 16px;
  font-weight: bold;
  display: inline;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  padding: env(safe-area-inset);
  min-height: 100vh;
  overflow: auto;
  font-family: IBM Plex Sans, Roboto, sans-serif;
  background-color: rgb(229, 226, 226);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

