.container_home_btn {
  width: 100%;
  margin: 0 auto;
}

.container_user_profile_info {
  display: flex;
  flex-direction: column;
}

.container_user_profile_image {
  width: 100%;
  margin: 0 auto;
}

.profile_image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
  object-position: 50% 50%;
  padding-right: 2px;
  padding-left: 2px;
}

.user_profile_header {
  margin-top: 0;
}

.error_message_no_user {
  color: red;
}

@media only screen and (min-width: 568px) {
  .container_user_profile {
    max-width: 800px;
    margin: 0 auto;
  }

  .container_user_profile_section {
    display: flex;
    flex-wrap: wrap;
  }

  .container_home_btn {
    max-width: 1400px;
    margin-bottom: 50px;
  }

  .container_user_profile_image {
    margin-left: 0;
    width: 40%;
  }

  .container_user_profile_info {
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .container_user_profile_image {
    width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 736px) {
  .profile_image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 10px;
    object-position: 50% 50%;
    padding-right: 10px;
    padding-left: 10px;
  }
}
