.AddArticleForm {
  padding-bottom: 40px;
  padding-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.AddArticleForm_lights_off {
  background-color: rgba(0, 0, 0, 0.7);
}

.AddArticleForm_lights_on {
  background-image: linear-gradient(
      rgba(220, 220, 220, 0.2),
      rgba(220, 220, 220, 0.2)
    ),
    url("../../images/main_bg.jpeg");
}

.container_AddArticleForm_header {
  text-align: center;
}

.container_AddArticleForm_title,
.container_AddArticleForm_image_url {
  width: 80%;
  margin: 20px auto;
}

.label_add_article_form {
  text-align: center;
  font-weight: bold;
}

.AddArticleForm_article_image_url,
.AddArticleForm_article_title {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
}

.AddArticleForm_article_content {
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
  font-size: 16px;
  height: 350px;
}

.AddArticleForm_select {
  margin: 10px auto;
  font-size: 16px;
  min-width: 200px;
  padding-left: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
  font-size: 16px;
}

.AddArticleForm_article_image_url:focus,
.AddArticleForm_article_title:focus,
.AddArticleForm_select:focus,
.AddArticleForm_article_content:focus {
  outline-width: 0;
}

.container_AddArticleForm_btn {
  margin-top: 10px;
  display: flex;
}

.AddArticleForm_post_btn,
.AddArticleForm_cancel_btn {
  display: block;
  width: 25%;
  margin: 10px auto;
  border-radius: 10px;
  border: none;
  background-color: rgb(51, 160, 255);
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}

.AddArticleForm_post_btn:hover,
.AddArticleForm_cancel_btn:hover {
  cursor: pointer;
}

.AddArticleForm_post_btn:focus,
.AddArticleForm_cancel_btn:focus {
  outline-width: 0;
}
