.category_link {
  text-decoration: none;
  color: black;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(255, 255, 255, 0);
}

.category_link:focus {
  border-left: 3px solid rgb(51, 160, 255);
  background-color: rgba(109, 108, 108, 0.2);
  color: rgb(51, 160, 255);
}

.category_link:hover {
  background-color: rgba(109, 108, 108, 0.2);
}

.lights_off_category_bg {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.lights_off_category_bg:focus {
  background-color: rgba(214, 214, 214, 0.2);
}

.lights_off_category_bg:hover {
  background-color: rgba(214, 214, 214, 0.2);
}
