.container_user_account_info {
  display: flex;
  flex-direction: column;
  height: 220px;
  padding-right: 3px;
  padding-left: 3px;
}

.container_user_account_image {
  width: 100%;
  margin: 0 auto;
}

.account_image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border: 1px solid rgb(185, 185, 185);
}

.UserAccount_edit_btn {
  width: 98%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  margin-bottom: 50px;
  background-color: rgb(51, 160, 255);
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.UserAccount_edit_btn:hover {
  cursor: pointer;
}

.UserAccount_edit_btn:focus {
  outline-width: 0;
}

.user_account_header {
  margin-top: 0;
}

.user_profile_text {
  margin-top: 0;
}

@media only screen and (min-width: 568px) {
  .container_user_account {
    max-width: 800px;
    margin: 0 auto;
  }

  .container_user_account_image {
    margin-left: 0;
    width: 40%;
  }

  .container_user_account_info {
    width: 50%;
  }

  .container_UserAccount_profile {
    display: flex;
    flex-wrap: wrap;
  }

  .container_UserAccount_edit_btn {
    text-align: left;
  }

  .UserAccount_edit_btn {
    width: 40%;
  }
}

@media only screen and (max-width: 500px) {
  .container_user_account_image {
    width: 100%;
    margin: 0 auto;
  }
}
