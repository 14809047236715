.container_article_image {
  width: 100%;
  margin: 30px auto;
}

.article_image {
  width: 100%;
  object-fit: cover;
  height: 300px;
}

.container_article {
  max-width: 800px;
  margin: 100px auto;
  padding-bottom: 50px;
  padding-right: 0;
}

.link_user_profile {
  text-decoration: none;
  color: rgb(51, 160, 255);
  font-weight: bold;
}

.link_user_profile:hover {
  text-decoration: underline;
}

.article_date,
.article_author {
  font-size: 16px;
}

.article_content {
  white-space: pre-line;
  text-align: justify;
  text-indent: 5%;
}

.Article_edit_post_btn {
  min-width: 200px;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  background-color: rgb(90, 90, 90);
  padding: 10px;
  color: white;
  font-weight: bold;
  background-color: rgb(51, 160, 255);
}

.Article_edit_post_btn:hover {
  cursor: pointer;
}

.Article_edit_post_btn:focus {
  outline-width: 0;
}

@media only screen and (min-width: 568px) {
  .container_article_image {
    max-width: 800px;
  }
  .article_image {
    height: 400px;
    object-fit: cover;
  }
}
