.container_top_users_list {
  margin-top: 14px;
  margin-right: 50px;
  width: 30%;
  border: 1px solid rgb(83, 82, 82);
  border-radius: 5px;
  height: 100%;
  padding-bottom: 20px;
  color: black;
  box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
}

.top_users_title {
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: rgba(109, 108, 108, 0.2);
  margin-top: 0;
}

.flash_icon {
  max-width: 20px;
  vertical-align: middle;
}

.container_top_users_list_lights_off {
  margin-top: 14px;
  margin-right: 50px;
  width: 30%;
  border: 1px solid rgb(83, 82, 82);
  border-radius: 5px;
  height: fit-content;
  padding-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
}

.flex_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container_topbar_menu {
  display: flex;
  width: 100%;
  height: 60px;
}

.container_dropdown_menu {
  width: 75%;
  height: 100%;
}

.container_topbar_create_btn {
  width: 25%;
  height: 100%;
}

.container_articles_section {
  width: 80%;
}

.container_sidebar_create_post_btn {
  width: 100%;
}

.right_sidebar_menu {
  width: 20%;
  border: 1px solid rgb(83, 82, 82);
  border-radius: 5px;
  padding-bottom: 20px;
  height: 100%;
  font-weight: bold;
  box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
}

.lights_on_bg {
  background-color: rgba(255, 255, 255, 0.8);
}

.lights_off_bg {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.lights_off_bg_shadow {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
}

.create_post_btn_bg {
  color: white;
  background-color: rgb(51, 160, 255);
  border: none;
}

@media only screen and (max-width: 768px) {
  .hide_sidebar_menu,
  .hide_top_users_list {
    display: none;
  }

  .container_articles_section {
    width: 100%;
  }

  .lights_on_bg {
    padding-bottom: 25px;
    margin-right: 10px;
    margin-left: 10px;
    box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
    border-radius: 2px;
  }

  .lights_off_bg {
    background-color: rgba(0, 0, 0, 0.7);
    padding-bottom: 25px;
    margin-right: 10px;
    margin-left: 10px;
    box-shadow: 6px 6px 6px 1px rgb(0, 0, 0);
    border-radius: 2px;
  }

  .mobile_top_users_title {
    padding-top: 10px;
    text-decoration: underline;
  }
}

@media only screen and (min-width: 769px) {
  .hide_topbar_menu {
    display: none;
  }

  .hide_top_users {
    display: none;
  }

  .right_sidebar_menu {
    margin-top: 14px;
  }
}
