.bg_mobile_view_registration_page {
  display: none;
}

.container_desktop_view_registration_hero {
  display: none;
}

.logo_registration {
  width: 150px;
  margin: 0 auto;
}

#header_registration_form {
  margin-top: 0;
  color: black;
}

#no_radius {
  border-radius: 0px;
}

.RegistrationForm {
  padding-bottom: 30px;
  width: 100%;
  min-height: 100vh;
  background-image: url("../../images/main_bg.jpeg");
}

.container_RegistrationForm_username,
.container_RegistrationForm_password,
.container_RegistrationForm_profile,
.container_RegistrationForm_email,
.container_RegistrationForm_image_url,
.container_RegistrationForm_fullname {
  width: 80%;
  margin: 10px auto;
}

#label_registration_fullname,
#label_registration_username,
#label_registration_password,
#label_registration_email,
#label_registration_profile,
#label_registration_image_url {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.RegistrationForm_email,
.RegistrationForm_username,
.RegistrationForm_image_url,
.RegistrationForm_password,
.RegistrationForm_fullname {
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
  font-size: 16px;
}

.RegistrationForm_email:focus,
.RegistrationForm_username:focus,
.RegistrationForm_image_url:focus,
.RegistrationForm_password:focus,
.RegistrationForm_fullname:focus,
.RegistrationForm_profile:focus {
  outline-width: 0;
}

.RegistrationForm_email:hover,
.RegistrationForm_username:hover,
.RegistrationForm_image_url:hover,
.RegistrationForm_password:hover,
.RegistrationForm_fullname:hover,
.RegistrationForm_profile:hover {
  border: 1px solid rgb(110, 110, 110);
}

.RegistrationForm_profile {
  display: block;
  font-size: 16px;
  padding: 10px;
  margin: 10px auto;
  text-align: left;
  width: 100%;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  resize: none;
  min-height: 150px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.39), 0 -1px 1px #fff, 0 1px 0 #fff;
}

.RegistrationForm_submit_btn {
  min-width: 200px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  background-color: white;
  color: black;
  font-weight: bold;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow: 2px 2px 5px;
}

.RegistrationForm_submit_btn:hover {
  cursor: pointer;
  border: 1px solid rgb(110, 110, 110);
}

.RegistrationForm_login_link {
  text-decoration: none;
  color: teal;
}

.red {
  margin-top: 2px;
  color: rgb(255, 0, 0);
}

#message_redirect {
  color: black;
}
@media only screen and (min-width: 1024px) {
  .container_desktop_view_registration_hero {
    display: block;
    font-size: 30px;
    padding-top: 250px;
    width: 75%;
    margin: 0 auto;
  }

  .container_registration_page {
    display: flex;
    min-height: 100vh;
  }

  .container_registration_form {
    width: 30%;
    min-height: 100vh;
  }

  .RegistrationForm {
    background-image: none;
    background-color: rgb(211, 211, 211);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .bg_desktop_view_registration_page {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../images/celebration.jpeg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    overflow: auto;
    width: 70%;
  }
}
