.container_article_page_return_link {
  max-width: 1400px;
  margin: 0 auto;
  text-align: left;
  padding-left: 0;
}

.article_page_return_link {
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
}

.article_page_return_link:hover {
  text-decoration: underline;
}

.lights_off_return_btn {
  color: white;
}

.container_ArticlePage {
  padding-top: 20px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.ArticlePage_lights_off {
  background-color: rgba(0, 0, 0, 0.85);
}

.ArticlePage_lights_on {
  background-color: rgba(215, 215, 215, 0.9);
}

.error_message_no_article {
  color: red;
}
