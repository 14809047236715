.top_users_list {
  padding: 0;
  width: 100%;
  margin: 0 auto;
}

.top_users_list_item {
  list-style: none;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
}

.top_users_list_item:hover {
  background-color: rgba(109, 108, 108, 0.2);
}

.top_users_list_item_lights_off:hover {
  background-color: rgba(109, 108, 108, 0.2);
}

.container_top_user_username {
  width: 40%;
  text-align: left;
}

.top_users_link {
  text-decoration: none;
  color: black;
  word-wrap: break-word;
}

.top_users_link_lights_off {
  text-decoration: none;
  color: white;
  word-wrap: break-word;
}

.container_top_user_image {
  width: 20%;
  margin-left: 25px;
}

.top_user_image {
  width: 20px;
  height: 20px;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: 10px;
}

.container_top_user_chatbox {
  width: 15%;
}

.top_user_chatbox {
  width: 20px;
}

@media only screen and (max-width: 768px) {
  .hide_icons {
    display: none;
  }

  .top_users_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .top_users_list_item {
    margin: 0;
    padding: 0;
    display: block;
  }

  .container_top_user_username {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
}
