.ticker_title {
  font-weight: bold;
  font-size: 20px;
}

@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.tcontainer {
  width: 100%;
  overflow: hidden;
}

.ticker-wrap {
  width: 100%;
  padding-left: 100%;
  background-color: black;
  margin-bottom: 30px;
  margin-top: 20px;
}

.ticker-wrap-lights-off {
  width: 100%;
  padding-left: 100%;
  background-color: white;
  margin-bottom: 30px;
  margin-top: 20px;
}

.ticker-move {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 90s;
  animation-delay: -2s;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ticker-move:hover {
  animation-play-state: paused;
}

.ticker-item {
  display: inline-block;
  padding: 0 2rem;
  -webkit-font-smoothing: antialiased;
}

.ticker_links {
  text-decoration: none;
  font-weight: bold;
  color: white;
  font-size: 24px;
}

.ticker_links_lights_off {
  text-decoration: none;
  font-weight: bold;
  color: black;
  font-size: 24px;
}

@media only screen and (max-width: 768px) {
  .ticker-move {
    animation-duration: 120s;
  }
}
