.EditCommentForm {
  margin-bottom: 100px;
  margin-top: 20px;
}

.label_edit_comment_form {
  display: block;
  width: 100%;
  margin: 5px auto;
  text-align: left;
  font-size: 16px;
}

.EditCommentForm_comment_box {
  width: 100%;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 5px;
  padding-left: 10px;
  resize: none;
  border-radius: 10px;
  text-align: left;
  font-size: 16px;
}

.EditCommentForm_comment_box:focus {
  outline-width: 0;
}

.container_EditCommentForm_btns {
  display: flex;
}

.EditCommentForm_submit_btn,
.EditCommentForm_cancel_btn {
  display: block;
  margin: 10px auto;
  border-radius: 10px;
  min-width: 200px;
  padding: 6px;
  font-size: 16px;
  background-color: rgb(51, 160, 255);
  color: white;
  font-weight: bold;
  border: none;
}

.EditCommentForm_submit_btn:hover,
.EditCommentForm_cancel_btn:hover {
  cursor: pointer;
}

.EditCommentForm_submit_btn:focus,
.EditCommentForm_cancel_btn:focus {
  outline: none;
}
