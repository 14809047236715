.AddCommentForm {
  max-width: 800px;
  margin-bottom: 100px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.label_add_comment_form {
  display: block;
  width: 90%;
  margin: 5px auto;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.AddCommentForm_comment_box {
  width: 90%;
  padding-left: 10px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 10px;
  resize: none;
  border-radius: 10px;
  text-align: left;
  font-size: 16px;
}

.AddCommentForm_comment_box:focus {
  outline-width: 0;
}

.AddCommentForm_submit_btn {
  display: block;
  margin: 10px auto;
  border-radius: 10px;
  min-width: 200px;
  padding: 6px;
  font-size: 16px;
  background-color: rgb(51, 160, 255);
  border: none;
  color: white;
  font-weight: bold;
}

.AddCommentForm_submit_btn:hover {
  cursor: pointer;
}

.AddCommentForm_submit_btn:focus {
  outline-width: 0;
}
