.select_menu {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 20px;
  padding-left: 5px;
}

.select_menu:focus {
  outline: none;
}
